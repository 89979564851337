const Git = () => {
  return (
    <div id='git'>
      <img id='gitImg' src='./images/giticon.png' alt='giticon'/>
      <h4 id='gitText'>Git</h4>
    </div>
  )
}

export default Git
