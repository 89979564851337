const JavaScript = () => {
  return (
    <div id='js'>
      <img id='jsImg' src='./images/jsicon.png' alt='jsicon'/>
      <h4 id='jsText'>JavaScript</h4>
    </div>
  )
}

export default JavaScript
