const HTML = () => {
  return (
    <div id='html'>
      <img id='htmlImg' src='./images/htmlicon.png' alt='htmlicon'/>
      <h4 id='htmlText'>HTML</h4>
    </div>
  )
}

export default HTML
